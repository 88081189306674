import {
  Flex,
  InputGroup,
  InputLeftAddon,
  Input,
  InputRightElement,
  Select,
  Button,
  Card,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import LineChart from './LineChart';

function mostraValor(valor) {
  return (
    'R$ ' +
    valor
      .toFixed(2)
      .replace('.', ',')
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  );
}

const Calculadora = () => {
  const table = [];
  const [tableData, setTableData] = useState([]);

  const [startingValue, setStartingValue] = useState();
  const handleStartingValueChange = (e) =>
    setStartingValue(parseFloat(e.target.value));

  const [monthlyValue, setMonthlyValue] = useState();
  const handleMonthlyValueChange = (e) =>
    setMonthlyValue(parseFloat(e.target.value));

  const [interest, setInterest] = useState();
  const handleInterestChange = (e) => setInterest(parseFloat(e.target.value));

  const [period, setPeriod] = useState();
  const handlePeriodChange = (e) => setPeriod(parseFloat(e.target.value));

  const [totalFinal, setTotalFinal] = useState('R$ 0,00');
  const [totalInvestido, setTotalInvestido] = useState('R$ 0,00');
  const [totalInterest, setTotalInterest] = useState('R$ 0,00');

  const [interestTime, setInterestTime] = useState('a');
  const handleInterestTimeChange = (e) => setInterestTime(e.target.value);

  const [periodTime, setPeriodTime] = useState('a');
  const handlePeriodTimeChange = (e) => setPeriodTime(e.target.value);

  const onSubmit = () => {
    table.length = 0;

    table.push({
      mes: 0,
      juros: 0,
      totalInvestido: startingValue || 0,
      totalJuros: 0,
      totalAcumulado: startingValue || 0,
    });

    const periodTimeSelected = periodTime === 'm' ? 1 : 12;
    const interestSelected =
      interestTime === 'm'
        ? interest || 0
        : (Math.pow(1 + (interest || 0) / 100, 1 / 12) - 1) * 100;
    for (let i = 0; i < period * periodTimeSelected; i++) {
      const row = table[table.length - 1];

      const juros = row.totalAcumulado * interestSelected * 0.01;
      const totalInvestido = row.totalInvestido + (monthlyValue || 0);
      const totalJuros = row.totalJuros + juros;
      const totalAcumulado = totalInvestido + totalJuros;
      table.push({
        mes: i + 1,
        juros: juros,
        totalInvestido: totalInvestido,
        totalJuros: totalJuros,
        totalAcumulado: totalAcumulado,
      });
    }
    setTotalFinal(mostraValor(table[table.length - 1].totalAcumulado));
    setTotalInvestido(mostraValor(table[table.length - 1].totalInvestido));
    setTotalInterest(mostraValor(table[table.length - 1].totalJuros));
    setTableData(table);
  };

  const clearData = () => {
    setInterest(0);
    setStartingValue(0);
    setMonthlyValue(0);
    setPeriod(0);
    setTotalFinal('R$ 0,00');
    setTotalInvestido('R$ 0,00');
    setTotalInterest('R$ 0,00');
    setInterestTime('a');
    setPeriodTime('a');
    setTableData([]);
    setTableData([]);
  };

  return (
    <Flex flexDir='column' alignItems='center'>
      <Flex flexDir='column' mb={4}>
        <Flex flexDir={['column', 'column', 'column', 'row', 'row']}>
          <Flex
            w='100px'
            justifyContent={['start', 'start', 'start', 'end', 'end']}
            mr={2}
            alignItems='center'
          >
            <Text>Valor inicial</Text>
          </Flex>
          <InputGroup w={300} mb={2}>
            <InputLeftAddon children='R$' />
            <Input
              type='number'
              value={startingValue}
              placeholder='0,00'
              onChange={handleStartingValueChange}
              borderColor='#E2E8F0'
            />
          </InputGroup>
          <Flex
            w='100px'
            justifyContent={['start', 'start', 'start', 'end', 'end']}
            mr={2}
            alignItems='center'
          >
            <Text>Valor mensal</Text>
          </Flex>
          <InputGroup w={300} mb={2}>
            <InputLeftAddon children='R$' />
            <Input
              borderColor='#E2E8F0'
              type='number'
              value={monthlyValue}
              placeholder='0,00'
              onChange={handleMonthlyValueChange}
            />
          </InputGroup>
        </Flex>
        <Flex flexDir={['column', 'column', 'column', 'row', 'row']}>
          <Flex
            w='100px'
            justifyContent={['start', 'start', 'start', 'end', 'end']}
            mr={2}
            alignItems='center'
          >
            <Text>Taxa de juros</Text>
          </Flex>
          <InputGroup w={300} mb={2}>
            <InputLeftAddon children='%' />
            <Input
              borderColor='#E2E8F0'
              type='number'
              value={interest}
              placeholder='0'
              onChange={handleInterestChange}
            />
            <InputRightElement w='30'>
              <Select
                defaultValue={1}
                borderLeftRadius={0}
                value={interestTime}
                onChange={handleInterestTimeChange}
              >
                <option value='m'>Mensal</option>
                <option value='a'>Anual</option>
              </Select>
            </InputRightElement>
          </InputGroup>
          <Flex
            w='100px'
            justifyContent={['start', 'start', 'start', 'end', 'end']}
            mr={2}
            alignItems='center'
          >
            <Text>Período</Text>
          </Flex>
          <InputGroup w={300} mb={2}>
            <Input
              borderColor='#E2E8F0'
              type='number'
              value={period}
              placeholder='0'
              onChange={handlePeriodChange}
            />
            <InputRightElement w='30'>
              <Select
                defaultValue={1}
                borderLeftRadius={0}
                value={periodTime}
                onChange={handlePeriodTimeChange}
              >
                <option value='m'>Mes(es)</option>
                <option value='a'>Ano(s)</option>
              </Select>
            </InputRightElement>
          </InputGroup>
        </Flex>
        <Flex justifyContent='space-between'>
          <Button variant='outline' w={20} onClick={onSubmit}>
            Calcular
          </Button>

          <Button variant='outline' w={20} onClick={clearData}>
            Limpar
          </Button>
        </Flex>
      </Flex>
      <Flex flexDir='column' w='100%' alignItems='center'>
        <Text>Resultado</Text>
        <Flex flexDir={['column', 'column', 'row', 'row']}>
          <Flex
            flexDir='column'
            border='1px'
            borderColor='#E2E8F0'
            borderRadius='md'
            p='3'
            w={200}
          >
            <Text>Valor total final</Text>
            <Text fontSize='2xl'>{totalFinal}</Text>
          </Flex>
          <Flex
            flexDir='column'
            border='1px'
            borderColor='#E2E8F0'
            borderRadius='md'
            p='3'
            my={[4, 4, 0, 0]}
            mx={[0, 0, 4, 4]}
            w={200}
          >
            <Text>Valor total investido</Text>
            <Text fontSize='2xl'>{totalInvestido}</Text>
          </Flex>
          <Flex
            flexDir='column'
            border='1px'
            borderColor='#E2E8F0'
            borderRadius='md'
            p='3'
            w={200}
          >
            <Text>Total em juros</Text>
            <Text fontSize='2xl'>{totalInterest}</Text>
          </Flex>
        </Flex>
        {!!tableData.length && <LineChart data={tableData} />}
      </Flex>
      {!!tableData.length && (
        <Card mt={5} overflowX='auto' maxW='100%'>
          <TableContainer>
            <Table variant='simple'>
              <Thead>
                <Tr>
                  <Th>Mês</Th>
                  <Th>Juros</Th>
                  <Th>Total Investido</Th>
                  <Th>Total Juros</Th>
                  <Th>Total Acumulado</Th>
                </Tr>
              </Thead>
              <Tbody>
                {tableData.map((row) => (
                  <Tr>
                    <Td>{row.mes}</Td>
                    <Td>{mostraValor(row.juros)}</Td>
                    <Td>{mostraValor(row.totalInvestido)}</Td>
                    <Td>{mostraValor(row.totalJuros)}</Td>
                    <Td>{mostraValor(row.totalAcumulado)}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Card>
      )}
    </Flex>
  );
};

export default Calculadora;
